import React, { useEffect } from "react"
import { useState } from "react"
import "./screen/Profile"
import "./screen/Kyc"
import "./screen/portfolio.css"
import searchicon from "../assets/searchicon.svg"
import calender from "../assets/calender.svg"
import downarrow from "../assets/downarrow.svg"
import tableicon from "../assets/tableicon.svg"
import buyicon from "../assets/buyprofileitable.svg"
import sellicon from "../assets/sellprofiletable.svg"
import profilemore from "../assets/profilemoreicon.svg"
import first from "../assets/First.svg"
import prev from "../assets/Prev.svg"
import tablemore from "../assets/dateprofilenextpg.svg"
import next from "../assets/Next.svg"
import last from "../assets/Last.svg"
import Header from "./components/header"
import Sidebar from "./components/sidebar"
import { getBuySellAssets } from "../services/Assests_Buy_sell"
import { getUserSession } from "../util/SessionStorage"

const Portfolio = () => {
  const [darkmode, setDarkmode] = useState(false)
  const [kyc, setKyc] = useState(true)
  const [isClicked, setClicked] = useState(false)
  const [selectedButton, setSelectedButton] = useState("Buy")
  const [assetData, setassetData] = useState([])
  const [selectedType, setselectedType] = useState("")
  const [popUp, setpopUp] = useState(false)
  const [selectedAssetTransactions, setSelectedAssetTransactions] = useState([])

  useEffect(() => {
    try {
      const user = getUserSession()
      if (user.data.valid) {
        getBuySellAssets(user.data.user._id, user.data.token)
          .then((result) => {
            if (result.data.valid) {
              setassetData(result.data.userAssets)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  console.log("portfolio data debug ", assetData)

  const handleButtonClick = (transactions) => {
    setKyc(false)
    setpopUp(true)
    setSelectedAssetTransactions(transactions)
  }

  const handleClickedButton = () => {
    setClicked(true)
  }

  const handleSelectorClick = (type) => {
    setSelectedButton(type)
    console.log(type)
  }

  const closeModal = () => {
    setpopUp(false)
    setSelectedAssetTransactions([])
  }

  return (
    <>
      <body className={darkmode ? "dark_mode" : "light_mode"}>
        <Header heading="Portfolio"></Header>
        <div className="divisioncomp">
          <Sidebar screenName="Portfolio" />

          <div className="ProfileCom">
            {/* table header section */}
            {/* <div className="Head_screen">
              <div className="portfolio_Head_screen_button">
                <button
                  className={
                    selectedButton === "Buy" ? "shares" : "Commodities"
                  }
                  onClick={() => handleSelectorClick("Buy")}
                >
                  BUY ASSETS
                </button>
                <button
                  className={
                    selectedButton === "Sell" ? "shares" : "Commodities"
                  }
                  onClick={() => handleSelectorClick("Sell")}
                >
                  SELL ASSETS
                </button>
              </div>
            </div> */}

            {/* table header section end */}

            <div className="bottom_screen">
              <table className="profile_table">
                <thead className="profile_thead">
                  <tr className="profile_tr">
                    {/* <td>
                      Shares
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Leverage
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Entry
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Lost Price <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Stop Loss <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Take Profit <img src={tableicon} alt="" />
                    </td>
                    <td>
                      P&L <img src={tableicon} alt="" />
                    </td> */}
                    <td>
                      Shares
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Type
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      quantity
                      <img src={tableicon} alt="" />
                    </td>
                    {/* <td>
                      Lost Price <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Stop Loss <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Take Profit <img src={tableicon} alt="" />
                    </td>
                    <td>
                      P&L <img src={tableicon} alt="" />
                    </td> */}
                  </tr>
                </thead>
                <tbody className="profile_tbody" onClick={handleClickedButton}>
                  {assetData.map((data, index) => (
                    <tr key={index}>
                      <td className="profile_shares">
                        <button className="circle_table">S</button>{" "}
                        <span>{data.asset_name}</span>
                      </td>
                      <td className="profile_lastprice">{data.asset_type}</td>
                      <td className="profilechange">{data.quantity}</td>

                      {/* <td className="profilevolume">{data.volume}</td>
                      <td>
                        <div className="main-container">
                          <div className="frame-1">
                            <span className="sl">SL</span>
                            <span className="multiple">Multiple</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="main-container">
                          <div className="frame-1">
                            <span className="sl">SL</span>
                            <span className="multiple">Multiple</span>
                          </div>
                        </div>
                      </td>
                      <td>{data.pnl}</td> */}
                      <td>
                        {/* <button
                          className="profile_buy_sell"
                          onClick={() =>
                            handleButtonClick("Buy", data.userAssetTransactions)
                          }
                        >
                          Buy <img src={buyicon} alt="" />
                        </button>{" "}
                        <button
                          className="profile_buy_sell profile_sell"
                          onClick={() =>
                            handleButtonClick(
                              "Sell",
                              data.userAssetTransactions
                            )
                          }
                        >
                          Sell <img src={sellicon} alt="" />
                        </button>{" "}
                        <img
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/96…ea138cf2?apiKey=382c3475e0c0434aab50e06e7c6506ec&"
                          alt=""
                        />{" "} */}
                        <button
                          className="profile_details"
                          onClick={() =>
                            handleButtonClick(data.userAssetTransactions)
                          }
                        >
                          Asset Details
                        </button>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {popUp && (
          <div className="modal">
            <div className="modal-content">
              {/* <span className="close" onClick={closeModal}>
                &times;
              </span> */}
              <h2>{selectedType} Transactions</h2>
              <table className="profile_table">
                <thead className="profile_thead">
                  <tr className="profile_tr">
                    <td>
                      Type
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Quantity
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Price
                      <img src={tableicon} alt="" />
                    </td>
                    <td>
                      Date
                      <img src={tableicon} alt="" />
                    </td>
                  </tr>
                </thead>
                <tbody className="profile_tbody">
                  {selectedAssetTransactions.map((transaction, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          color:
                            transaction.transaction_type === "Buy"
                              ? "green"
                              : "red",
                        }}
                      >
                        {transaction.transaction_type}
                      </td>
                      <td>{transaction.quantity}</td>
                      <td>{transaction.price}</td>
                      <td>{new Date(transaction.date).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="close" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </body>
    </>
  )
}

export default Portfolio
