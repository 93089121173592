import React from "react"
import "./css/confirmbox.css"

export default function ConfirmBox({
  type,
  data,
  isopen,
  isclose,
  handleLogic,
}) {
  const handleConfirm = () => {
    handleLogic()
    isclose()
  }

  const handleCancel = () => {
    isclose()
  }

  return (
    <div>
      {isopen && (
        <div className="popup-wrapper">
          <div className="popup">
            <h3>Are You Sure To {type}?</h3>
            {data !== undefined && data !== null && (
              <h5 style={{ marginTop: 10 }}>Quantity : {data}</h5>
            )}
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  )
}
