import axios from "axios"
import { Constants } from "../util/Constants"

const walletTransactions = async (token, startDate, endDate, userId) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL +
        Constants.ROUTES.USERS +
        Constants.ROUTES.WALLET_TRANSACTION,
      {
        startDate,
        endDate,
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const walletRecharge = async (token, userId, amount, transaction_id, file) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL + Constants.ROUTES.USER_PAYMENT,
      { userId, amount, transaction_id, file },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const getUserTransactions = async (token, userId) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL +
        Constants.ROUTES.USER_PAYMENT +
        Constants.ROUTES.FILTER,
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const WalletServices = {
  walletTransactions,
  walletRecharge,
  getUserTransactions,
}

export default WalletServices
