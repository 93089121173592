import axios from "axios"
import { Constants } from "../util/Constants"

const buy_Sell_Assest = async (
  token,
  userId,
  asset_name,
  asset_type,
  quantity,
  price,
  transaction_type
) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL + Constants.ROUTES.USER_ASSET,
      { userId, asset_name, asset_type, quantity, price, transaction_type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

const getBuySellAssets = async (userId, token) => {
  try {
    const response = await axios.post(
      Constants.SERVER.API_URL +
        Constants.ROUTES.USER_ASSET +
        Constants.ROUTES.FILTER,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status === Constants.STATUS.OK) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}

export { buy_Sell_Assest, getBuySellAssets }
