import React, { useEffect, useState } from "react"
import { getUserSession } from "../../util/SessionStorage"
import WalletServices from "../../services/Wallet_Transaction"
import "./css/transactionmodal.css"
import { Constants } from "../../util/Constants"

export default function Transaction_Modal({ isOpen, onClose }) {
  const [transactionRequests, settransactionRequests] = useState([])

  useEffect(() => {
    async function fetchUserTransactions() {
      const user = getUserSession()
      if (user) {
        try {
          const result = await WalletServices.getUserTransactions(
            user.data.token,
            user.data.user._id
          )

          settransactionRequests(result.data.transactions)
        } catch (error) {
          console.log(error)
        }
      }
    }
    fetchUserTransactions()
  }, [])

  const formatDateString = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  return (
    <>
      <div className="bottom_screen">
        <div className="payment_details">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {transactionRequests.map((request) => {
                return (
                  <tr key={request._id}>
                    <td>{formatDateString(request.date)}</td>
                    <td>{request.transaction_id}</td>
                    <td>{request.amount}</td>
                    <td>
                      {request.status === Constants.STATUS.ACTIVE
                        ? "Pending"
                        : "Completed"}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
