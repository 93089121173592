import React, { useEffect, useState } from "react"
import "./Profile.css"
import searchicon from "../../assets/searchicon.svg"
import calender from "../../assets/calender.svg"
import downarrow from "../../assets/downarrow.svg"
import tableicon from "../../assets/tableicon.svg"
import buyicon from "../../assets/buyprofileitable.svg"
import sellicon from "../../assets/sellprofiletable.svg"
import watchlist_before from "../../assets/watchlist_before.png"
import watchlist_after from "../../assets/watchlist_after.png"
import profilemore from "../../assets/profilemoreicon.svg"
import first from "../../assets/First.svg"
import prev from "../../assets/Prev.svg"
import tablemore from "../../assets/dateprofilenextpg.svg"
import next from "../../assets/Next.svg"
import last from "../../assets/Last.svg"
import axios from "axios"
import { Tooltip } from "react-tooltip"

const Profile = ({ setKyc, setClicked, setDetails, setParentStockData }) => {
  const [stocks, setStocks] = useState([])
  const [stockAllData, setStockAllData] = useState([])
  const [startIndex, setStartIndex] = useState(1)
  // const [loadingTimeData, setLoadingTimeData] = useState([]);

  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageButtons, setPageButtons] = useState([])
  const [watchlist, setwatchlist] = useState(false)
  const [watchlistStates, setWatchlistStates] = useState({})

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return
    }
    setCurrentPage(page)
    generatePageButtons()
    setStartIndex((page - 1) * itemsPerPage)
  }

  // Generate page buttons dynamically
  const generatePageButtons = () => {
    const buttons = []
    setPageButtons(buttons)

    for (let i = 1; i <= totalPages; i++) {
      buttons.push(i)
    }
    setPageButtons(buttons)
  }

  const handleButtonClick = ({ name, data }) => {
    // setKyc(false)
    setDetails((prevDetails) => ({
      ...prevDetails,
      stockName: name,
      stockData: data,
    }))
    setClicked(true)

    // console.log(name, data)
  }

  const handleClickedButton = (data) => {
    setDetails(data)
    setClicked(true)
  }

  const handleWatchlist = (rowIndex, stockName, stockData) => {
    // setwatchlist(!watchlist)
    setWatchlistStates({
      ...watchlistStates,
      [rowIndex]: !watchlistStates[rowIndex],
    })
  }

  const getStockData = async (symbols) => {
    const secretKey = "MSrIDco7b2pAUe3t39WZgax110d2kVefKD7dhbuc"
    const apiKey = "CKP3MQZGSRKBGC1LP688"

    const username = "CKP3MQZGSRKBGC1LP688"
    const password = "MSrIDco7b2pAUe3t39WZgax110d2kVefKD7dhbuc"

    // Encoding username and password in base64
    const basicAuthCredentials = btoa(`${username}:${password}`)
    const headers = {
      Authorization: `Basic ${basicAuthCredentials}`,
    }

    {
      /**  
      
          //  bars api use for charts

      .get(
        `https://data.sandbox.alpaca.markets/v2/stocks/bars/latest?symbols=${symbols}`,
        { headers }
      )
    */
    }
    return await axios

      .get(
        `https://data.sandbox.alpaca.markets/v2/stocks/quotes/latest?symbols=${symbols}`,
        { headers }
      )
      .then((response) => {
        if (response) return response.data
      })
      .catch((error) => {
        console.error("Error fetching stock data:", error)
        return 0
      })
  }

  useEffect(() => {
    const fetchStocks = async () => {
      let loadingTimeData = []
      const symbols =
        "TSLA,WMT,SRNEQ,PTNRY,ACORQ,AMRSQ,JSM,BUSA,KMB,KMI,KMPB,LGI,LGIH,LGL,XXXX,ICE,ZPTA"
      try {
        // const categoryList = ["mostactive" , "gainers" , "losers" , "iexvolume" , "iexpercent" ];
        // for(let i = 0; i < categoryList.length; i++){
        // const response = await axios.get(`https://api.iex.cloud/v1/data/CORE/STOCK_COLLECTION/list?collectionName=${categoryList[i]}&token=pk_87153c5f922e4b51b66372381d1ec6aa`);
        const response = await getStockData(symbols)

        // console.log("response", response.toJson());
        // let previousData = loadingTimeData;
        // console.log("previousData", previousData    );
        loadingTimeData = loadingTimeData.concat(response)
        // }
        // console.log("loadingTimeData", loadingTimeData)
        setStockAllData(loadingTimeData)
        setStocks(loadingTimeData)
        setTotalPages(Math.ceil(loadingTimeData.length / itemsPerPage))
        generatePageButtons()
        setParentStockData(loadingTimeData.slice(0, 10))
        setCurrentPage(1)
        handlePageChange(1)
      } catch (error) {
        console.error("Error fetching stocks:", error)
      }
    }

    fetchStocks()
  }, [])

  const handleInputChange = (event) => {
    if (event.target.value === "" || !event.target.value) {
      setStocks(stockAllData)
    } else {
      const filteredData = stockAllData.filter((item) => {
        return item.companyName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      })
      setStocks(filteredData)
    }
  }

  return (
    <>
      {/* table header section */}
      <div className="Head_screen">
        <div className="Head_screen_button">
          <button className="shares">Shares</button>
          <button className="Commodities">Commodities</button>
          <button className="crypto">Crypto</button>
        </div>

        {/* <div className='Searchbar_comp'>
                    <input type="search" placeholder='Search here' className='search_bar' onChange={handleInputChange}/>
                    <img src={searchicon} alt="" />
                </div> */}

        {/* <div className="Calenderbar">
          <img className="calenderimg" src={calender} alt="" />
          <div className="calendertext">
            <h1>Filter Periode</h1>
            <p>4 June 2023 - 4 Jan 2024</p>
          </div>
          <img className="calenderarrowimg" src={downarrow} alt="" />
        </div> */}
      </div>

      {/* table header section end */}

      <div className="bottom_screen">
        <table className="profile_table">
          <thead className="profile_thead">
            {/* <tr className='profile_tr'>
                            <td className='rank' >Rank <img src={tableicon} alt="" /></td>
                            <td>Shares<img src={tableicon} alt="" /></td>
                            <td>Last Price<img src={tableicon} alt="" /></td>
                            <td>Change (24)<img src={tableicon} alt="" /></td>
                            <td>volume (24) <img src={tableicon} alt="" /></td>
                            <td>graph <img src={tableicon} alt="" /></td>
                        </tr> */}
            <tr className="profile_tr">
              <td className="rank">
                Rank <img src={tableicon} alt="" />
              </td>
              <td>
                Shares
                <img src={tableicon} alt="" />
              </td>
              <td>
                Ask Price
                <img src={tableicon} alt="" />
              </td>
              <td>
                Bid Price
                <img src={tableicon} alt="" />
              </td>
              <td>
                Bid Size <img src={tableicon} alt="" />
              </td>
              <td>
                graph <img src={tableicon} alt="" />
              </td>
            </tr>
          </thead>
          <tbody className="profile_tbody">
            {stocks
              .slice(startIndex, startIndex + itemsPerPage)
              .map((stock, index) =>
                //               //  <tr key={index} onClick={() => handleClickedButton(stock)}>
                //               // <tr key={index}>
                //               //   <td className='rankcircle'><button>{"#" + (startIndex + index + 1)}</button></td>
                //               //   <td className='profile_shares' ><button className='circle_table'>{stock.symbol}</button> <span>{stock.symbol}</span></td>
                //               //   <td className='profile_lastprice'>{"$" + stock.o}</td>
                //               //   <td className={stock.changePercent < 0 ? 'profilechangeNegative' : 'profilechange'}>{String(stock.changePercent).replace('-', '') + "%"}</td>
                //               //   <td className='profilevolume'>{"$" +(stock.volume != null ? stock.volume : 4859.9)}</td>
                //               //   <td className='graphimg' ><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&" alt="" /></td>
                //               //   <td><button className='profile_buy_sell' onClick= { handleButtonClick   }>Buy <img src={buyicon} alt=""  /></button> <button className='profile_buy_sell profile_sell' onClick= { handleButtonClick   }>Sell  <img src={sellicon} alt="" /></button> <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/96…ea138cf2?apiKey=382c3475e0c0434aab50e06e7c6506ec&" alt="" /> </td>
                //               //    <td><img src = {profilemore} alt="" className='profilemore ' /></td>
                //               // </tr>

                Object.keys(stock.quotes).map((symbol, idx) => (
                  <tr key={index + "-" + idx}>
                    <td className="rankcircle">
                      <button>{"#" + (startIndex + index + 1 + idx)}</button>
                    </td>
                    <td className="profile_shares">
                      <span>{symbol}</span>
                    </td>
                    <td className="profile_lastprice">
                      <span>{"$" + stock.quotes[symbol].ap}</span>
                    </td>
                    <td className="profile_lastprice">
                      <span>{"$" + stock.quotes[symbol].bp}</span>
                    </td>
                    <td className="profile_lastprice">
                      <span>{"$" + stock.quotes[symbol].bs}</span>
                    </td>
                    <td className="graphimg">
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/257d1f5b-a6bb-4a50-a714-cf2292c048f3?apiKey=382c3475e0c0434aab50e06e7c6506ec&"
                        alt=""
                      />
                    </td>
                    <td>
                      <button
                        className="profile_buy_sell"
                        onClick={() =>
                          handleButtonClick({
                            name: symbol,
                            data: stock.quotes[symbol],
                          })
                        }
                      >
                        Buy <img src={buyicon} alt="" />
                      </button>{" "}
                      <button
                        className="profile_buy_sell profile_sell"
                        onClick={() =>
                          handleButtonClick({
                            name: symbol,
                            data: stock.quotes[symbol],
                          })
                        }
                      >
                        Sell <img src={sellicon} alt="" />
                      </button>{" "}
                      {/* <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/96…ea138cf2?apiKey=382c3475e0c0434aab50e06e7c6506ec&"
                        alt=""
                      />{" "} */}
                      <img
                        onClick={() =>
                          handleWatchlist(idx, symbol, stock.quotes[symbol])
                        }
                        className="profile_watchlist"
                        src={
                          watchlistStates[idx]
                            ? watchlist_after
                            : watchlist_before
                        }
                        alt="Watchlist Icon"
                        style={{ width: 20, height: 20 }}
                      />
                      <span className="tooltip" style={{ fontSize: 8 }}>
                        {watchlistStates[idx]
                          ? "Remove From Watchlist"
                          : "Add To Watchlist"}
                      </span>
                    </td>
                  </tr>
                ))
              )}
          </tbody>
        </table>
      </div>
      <div className="profile_nextpage">
        <div>
          <p className="">
            Showing {stockAllData.length > 0 ? itemsPerPage + startIndex : 0}{" "}
            from {stockAllData.length} data
          </p>
        </div>
        <div className="profile_nextpage_btn">
          <>
            {/* Pagination controls */}
            <button
              className="profile_nextpage_btn_btn"
              onClick={() => handlePageChange(1)}
            >
              <img src={first} alt="first" />
            </button>
            <button
              className="profile_nextpage_btn_btn"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <img src={prev} alt="prev" />
            </button>
            {pageButtons.map((page, index) => {
              return (
                <button
                  key={page}
                  className={
                    currentPage === index + 1
                      ? "profile_nextpage_btn_black_btn"
                      : "profile_nextpage_btn_btn"
                  }
                  onClick={() => handlePageChange(index + 1)}
                >
                  {page}
                </button>
              )
            })}
            {/* {pageButtons} */}
            <button
              className="profile_nextpage_btn_btn"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <img src={next} alt="" />
            </button>
            <button
              className="profile_nextpage_btn_btn"
              onClick={() => handlePageChange(totalPages)}
            >
              <img src={last} alt="" />
            </button>
          </>
        </div>
      </div>
    </>
  )
}

export default Profile
