import React, { useState } from "react"
import Header from "./components/header"
import Sidebar from "./components/sidebar"
import "../layout/components/css/wallet.css"
import WalletServices from "../services/Wallet_Transaction"
import WalletModal from "./components/walletModal"
import Transaction_Modal from "./components/transaction_modal"

export default function Wallet() {
  const [darkmode, setDarkmode] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [transactions, settransactions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [transactionRequestModalOpen, setTransactionRequestModalOpen] =
    useState(false)
  const [selectedButton, setSelectedButton] = useState("Transaction History")

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value)
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value)
  }

  const formatDateString = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const user = JSON.parse(localStorage.getItem("user"))
      await WalletServices.walletTransactions(
        user.data.token,
        startDate,
        endDate,
        user.data.user._id
      )
        .then((result) => {
          settransactions([result])
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const openTransactionModal = () => {
    setTransactionRequestModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeTransactionModal = () => {
    setTransactionRequestModalOpen(false)
  }
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName)
    if (selectedButton === "Payment History") {
      openTransactionModal()
    }
  }

  return (
    <>
      <body className={darkmode ? "dark_mode" : "light_mode"}>
        <Header heading="Wallet"></Header>
        <div className="divisioncomp">
          <Sidebar />
          <div className="container">
            <div className="Head_screen">
              <div className="Head_screen_button">
                <button
                  className={
                    selectedButton === "Transaction History"
                      ? "shares"
                      : "Commodities"
                  }
                  onClick={() => handleButtonClick("Transaction History")}
                >
                  Transaction History
                </button>

                <button
                  className={
                    selectedButton === "Payment History"
                      ? "shares"
                      : "Commodities"
                  }
                  onClick={() => handleButtonClick("Payment History")}
                >
                  Payment History
                </button>
              </div>
              <button
                type="button"
                onClick={openModal}
                className="wallet-recharge-btn"
              >
                Recharge Wallet
              </button>
            </div>

            {selectedButton === "Transaction History" && (
              <>
                <div className="bottom_screen">
                  <div className="header-form">
                    <div className="row">
                      <form onSubmit={handleSubmit} className="form-row">
                        <h3>Start Date:</h3>
                        <input
                          type="date"
                          id="start_date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          required
                        />
                        <h3>End Date:</h3>
                        <input
                          type="date"
                          id="end_date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          required
                        />
                        <button type="submit">Submit</button>
                      </form>
                    </div>
                  </div>
                  <div className="transaction_details">
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Transaction Type</th>
                          <th>Narration</th>
                          <th>Recharge Amount</th>
                          <th>Balance</th>
                        </tr>
                      </thead>

                      <tbody>
                        {transactions.length > 0 ? (
                          transactions.map((transaction, index) =>
                            transaction.data.transactions.map((trans, idx) => (
                              <tr key={idx}>
                                <td>{formatDateString(trans.date)}</td>
                                <td>{trans.transation_type}</td>
                                <td>{trans.narration}</td>
                                <td>{trans.amount}</td>
                                <td>{trans.balance}</td>
                              </tr>
                            ))
                          )
                        ) : (
                          <div className="empty_transaction"></div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            {selectedButton === "Payment History" && (
              <Transaction_Modal
                isOpen={transactionRequestModalOpen}
                onClose={closeTransactionModal}
              />
            )}

            {isModalOpen && (
              <WalletModal isOpen={isModalOpen} onClose={closeModal} />
            )}
          </div>
        </div>
      </body>
    </>
  )
}
