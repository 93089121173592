export const Constants = {
  SERVER: {
    API_URL: "http://16.170.249.131:3000/api/v1",
    //API_URL: "http://localhost:7777/api/v1",
  },
  STATUS: {
    OK: 200,
    DEACTIVE: 0,
    ACTIVE: 1,
  },
  ROUTES: {
    USERS: "/users",
    REGISTER: "/register",
    LOGIN: "/login",
    WALLET_TRANSACTION: "/wallet-transaction",
    USER_PAYMENT: "/user-payment",
    FILTER: "/filter",
    USER_ASSET: "/user-asset",
  },
}
