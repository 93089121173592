import axios from "axios"
import { Constants } from "../util/Constants"
import { deleteUserSession, setUserSession } from "../util/SessionStorage"

const register = (
  email,
  password,
  dob,
  zip,
  name,
  city,
  phone,
  state,
  address,
  bank_name,
  national_id,
  national_id_file,
  bank_account_number
) => {
  try {
    return axios.post(Constants.SERVER.API_URL + Constants.ROUTES.REGISTER, {
      email,
      password,
      dob,
      name,
      zip,
      city,
      phone,
      state,
      address,
      bank_name,
      national_id,
      national_id_file,
      bank_account_number,
    })
  } catch (error) {
    console.log(error)
  }
}

const login = (username, password) => {
  return axios
    .post(Constants.SERVER.API_URL + Constants.ROUTES.LOGIN, {
      username: username,
      password: password,
    })
    .then((response) => {
      if (response.status == Constants.STATUS.OK) {
        setUserSession(response)
      }

      return response.data
    })
}

const logout = () => {
  deleteUserSession()
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
}

export default AuthService
