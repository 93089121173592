// Modal.js
import React, { useState } from "react"
import "./css/walletmodal.css"
import WalletServices from "../../services/Wallet_Transaction"
import { getUserSession } from "../../util/SessionStorage"
import ConfirmBox from "./confirmBox"

export default function WalletModal({ isOpen, onClose }) {
  const [amount, setAmount] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [file, setFile] = useState(null)
  const [showPopup, setShowPopup] = useState(false)

  const handleAmountChange = (e) => {
    setAmount(e.target.value)
  }

  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onloadend = () => {
        // Read the file content as base64 string
        const base64String = reader.result
        // Set the base64 string to the state
        setFile(base64String)
      }

      reader.readAsDataURL(file)
    }
  }

  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">This field is required!</div>
      )
    }
  }

  const openPopUp = () => {
    setShowPopup(true)
  }

  const closePopUp = () => {
    setShowPopup(false)
  }
  const onSubmit = () => {
    if ((amount, transactionId, file)) {
      const user = getUserSession()
      if (user) {
        WalletServices.walletRecharge(
          user.data.token,
          user.data.user._id,
          amount,
          transactionId,
          file
        )
          .then((result) => {
            onClose()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else {
      alert("Please Enter All Fields To Submit")
    }
  }

  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Recharge Wallet</h2>
            <input
              type="text"
              placeholder="Amount ( $ ) "
              value={amount}
              onChange={handleAmountChange}
              required
            />
            <input
              type="text"
              placeholder="Transaction ID or Refrence Number"
              value={transactionId}
              onChange={handleTransactionIdChange}
              required
            />
            <input
              type="file"
              accept="image/*,.pdf"
              onChange={handleFileChange}
              required
            />
            <div className="button-container">
              <button className="submit-btn" onClick={openPopUp}>
                Submit
              </button>
              <button className="close-btn" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <ConfirmBox
          type="Recharge Wallet"
          isopen={showPopup}
          isclose={closePopUp}
          handleLogic={onSubmit}
        />
      )}
    </>
  )
}
